exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-job-application-page-index-tsx": () => import("./../../../src/templates/JobApplicationPage/index.tsx" /* webpackChunkName: "component---src-templates-job-application-page-index-tsx" */),
  "component---src-templates-job-description-page-index-tsx": () => import("./../../../src/templates/JobDescriptionPage/index.tsx" /* webpackChunkName: "component---src-templates-job-description-page-index-tsx" */),
  "component---src-templates-job-list-page-index-tsx": () => import("./../../../src/templates/JobListPage/index.tsx" /* webpackChunkName: "component---src-templates-job-list-page-index-tsx" */),
  "component---src-templates-job-search-page-index-tsx": () => import("./../../../src/templates/JobSearchPage/index.tsx" /* webpackChunkName: "component---src-templates-job-search-page-index-tsx" */),
  "component---src-templates-job-send-to-friend-page-index-tsx": () => import("./../../../src/templates/JobSendToFriendPage/index.tsx" /* webpackChunkName: "component---src-templates-job-send-to-friend-page-index-tsx" */),
  "component---src-templates-regulatory-news-page-index-tsx": () => import("./../../../src/templates/RegulatoryNewsPage/index.tsx" /* webpackChunkName: "component---src-templates-regulatory-news-page-index-tsx" */),
  "component---src-templates-template-a-page-index-tsx": () => import("./../../../src/templates/TemplateAPage/index.tsx" /* webpackChunkName: "component---src-templates-template-a-page-index-tsx" */),
  "component---src-templates-template-b-page-index-tsx": () => import("./../../../src/templates/TemplateBPage/index.tsx" /* webpackChunkName: "component---src-templates-template-b-page-index-tsx" */),
  "component---src-templates-template-d-page-index-tsx": () => import("./../../../src/templates/TemplateDPage/index.tsx" /* webpackChunkName: "component---src-templates-template-d-page-index-tsx" */),
  "component---src-templates-template-e-page-index-tsx": () => import("./../../../src/templates/TemplateEPage/index.tsx" /* webpackChunkName: "component---src-templates-template-e-page-index-tsx" */),
  "component---src-templates-template-f-page-index-tsx": () => import("./../../../src/templates/TemplateFPage/index.tsx" /* webpackChunkName: "component---src-templates-template-f-page-index-tsx" */),
  "component---src-templates-template-h-page-index-tsx": () => import("./../../../src/templates/TemplateHPage/index.tsx" /* webpackChunkName: "component---src-templates-template-h-page-index-tsx" */),
  "component---src-templates-template-i-page-index-tsx": () => import("./../../../src/templates/TemplateIPage/index.tsx" /* webpackChunkName: "component---src-templates-template-i-page-index-tsx" */),
  "component---src-templates-template-j-page-index-tsx": () => import("./../../../src/templates/TemplateJPage/index.tsx" /* webpackChunkName: "component---src-templates-template-j-page-index-tsx" */),
  "component---src-templates-value-of-contents-page-index-tsx": () => import("./../../../src/templates/ValueOfContentsPage/index.tsx" /* webpackChunkName: "component---src-templates-value-of-contents-page-index-tsx" */)
}

