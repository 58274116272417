import React from 'react';

type Props = {
  children: React.ReactNode;
  location: Location;
};

type State = {
  thrownError: Error | null;
};

/**
 * This component catches any errors in its child component tree, logs those errors
 * to the console, and displays an error page instead.
 * https://reactjs.org/docs/error-boundaries.html
 *
 * This must be a class component as hooks don't support error boundaries
 */
/* istanbul ignore next */
class ErrorBoundary extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { thrownError: null };
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    const { location } = this.props;
    // Clear error if the location changes
    if (location.pathname !== prevProps.location.pathname) {
      if (prevState.thrownError) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          thrownError: null,
        });
      }
    }
  }

  public static getDerivedStateFromError(error: Error): State {
    return { thrownError: error };
  }

  // eslint-disable-next-line class-methods-use-this
  public componentDidCatch(error: Error, info: React.ErrorInfo): void {
    console.error('Caught error: ', error);
    console.error('Component Trace: ', info.componentStack);
  }

  public render(): React.ReactNode {
    const { thrownError }: State = this.state;
    const { children }: Props = this.props;

    if (!thrownError) {
      return children;
    }

    return <p aria-live="assertive">An error has occurred</p>;
  }
}

export default ErrorBoundary;
