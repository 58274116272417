import 'whatwg-fetch';
import elementClosest from 'element-closest';
import { GatsbyBrowser } from 'gatsby';
import React from 'react';
import PageTracking from 'components/PageTracking';
import ErrorBoundary from './src/components/ErrorBoundary';
import ThemeProvider from './src/theme';

elementClosest(window);

declare global {
  interface Window {
    isHydrated?: boolean;
  }
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => (
  <ThemeProvider>
    <PageTracking location={props.location}>
      <ErrorBoundary location={props.location}>{element}</ErrorBoundary>
    </PageTracking>
  </ThemeProvider>
);

export const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] = () => {
  window.isHydrated = true;
};
